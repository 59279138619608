<template>
    <v-container>
        <!-- <h1 class="headline">Conference Call</h1> -->

        <HyphenDatatable
    :headers="headers"
    :config="{resource:'on-site-consultation/expert', title: 'On-site consultation', rowClickUrl:'on-site-consultation', enabledActions: [ 'search', 'delete','hide-delete-action']}"
    />
         <!-- <v-data-table
    :headers="headers"
    :items="onSiteConsultationList"
    sort-by="name"
    class="elevation-1"
    @click:row="handleClick"
    :options.sync="options"
      :server-items-length="count"
      :items-per-page="10"
      :loading="loading"
  >
  <template v-slot:[`item.preferredDate`]="{ item }">
    {{formatDate(item.preferredDate, 'MMM Do YYYY hh:mm A')}}
  </template>
  <template v-slot:[`item.approvedDate`]="{ item }">
    {{formatDate(item.approvedDate, 'MMM Do YYYY hh:mm A')}}
  </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>On Site Consultation</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      </v-toolbar>
    </template>
    
    <template v-slot:no-data>
        No Data
      
    </template>
  </v-data-table> -->
    


    </v-container>
</template>



<script>
import HyphenDatatable from './component/HyphenDatatable.vue';

export default {
  components:{
    HyphenDatatable
  },
    data(){
        return{
            onSiteConsultationList:[],
            headers:[
                    {text: 'Id', value: 'id'},
                    {text: 'Title', value: 'title'},
                    {text: 'Inquiry Type', value: 'inquiryType'},
                    {text: 'Area', value: 'area'},
                    {text: 'Description', value: 'description'},
                    {text: 'Preferred Date/Time', value: 'preferredDate'},
                  
                    {text: 'Approved Date/Time', value: 'approvedDate'},
                    {text: 'Customer', value: 'customer.name'},
                    {text: 'Remarks', value: 'remarks'},
                    {text: 'Status', value: 'status'},
            ],
              count: null,
    loading:false,
    options:{},
        }
    },
    watch: {
     options:{
       handler () {
          this.getOnSiteConsultationList()
        },
        deep: true,
    }
  },
    methods:{
        getOnSiteConsultationList(){
          this.loading = true;
            this.$General.getOnSiteConsultation(this.options.page, this.options.itemsPerPage).then(data=>{
                this.onSiteConsultationList = data.data.data.list;
                this.count = data.data.data.count;
                this.loading = false;
                
                }).catch(error=>{
                  this.loading = false;
                    console.log(error);
                })
        },
        handleClick(args){
            this.$router.push({path:'on-site-consultation/'+args.id});
          console.log(args);
        //   this.$router.push({path:"/design/"+args.id});
        
        
        
      },
    },
    mounted(){
        this.getOnSiteConsultationList();
    }
}
</script>


<style scoped>

</style>