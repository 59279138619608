<template>
  <v-data-table
    :config = "config"
    :headers="headers"
    :items="items"
    sort-by="name"
    class="elevation-1"
    :loading = loading
    :options.sync="options"  
    :items-per-page="10"
    :server-items-length="count" 
  >
  <template v-slot:[`item.isBlocked`]="{ item }" v-if="blockEnabled">
    <v-chip
    class="ma-2"
    :color="item.isBlocked? 'red': 'primary'"
    text-color="white"
  >
    {{item.isBlocked?'Blocked':'Active'}}
  </v-chip>
</template>
<template v-slot:[`item.approvedDate`]="{ item }" >
   {{formatDate(item.approvedDate, 'Do MMM YYYY hh:mm A')}}

</template>
<template v-slot:[`item.preferredDate`]="{ item }" >
   {{formatDate(item.preferredDate, 'Do MMM YYYY hh:mm A')}}
</template>
<template v-slot:[`item.updatedDate`]="{ item }" >
   {{formatDate(item.updatedDate, 'Do MMM YYYY hh:mm A')}}
</template>
  <template v-slot:item.platform="{ item }">
      <v-icon small @click="handleRowClick(item)">mdi-eye</v-icon>
    </template>
    <template v-slot:item.title="{ item }">
      <a small @click="handleRowClick(item)">{{item.title}}</a>
    </template>
  <template v-slot:[`item.link`]="{ item }" >
   <v-btn 
     x-small
          rounded
          :href="item.link"
          color='blue'
          target="_blank"
          class="btn"
          >Click Here </v-btn></v-btn>
</template>
  <template v-slot:[`item.status`]="{ item }">
        <v-btn
          x-small
          rounded
          :color="getColor(item.status)"
          target="_blank"
          class="btn"
          >{{item.status}}</v-btn
        >
      </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ config.title }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="handleAction" v-if="config.actionUrl">New</v-btn>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px" :loading="true">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this user?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBlock" max-width="500px" :loading="true">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to {{ currentSelectedItem.isBlocked?'unblock': 'block' }} this {{blockText}}?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeBlock">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="blockItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container v-if="searchEnabled">
      <v-row>
          <v-col>
            <v-text-field append-icon="mdi-magnify"  v-model="searchText" label="Search" single-line hide-details
              @change="searchEvent($event)"></v-text-field>
          </v-col>
          <v-col md="3" v-if="deleteEnabled">
            <v-select label="Filter Records By" :items="['All', 'Deleted', 'Not Deleted']" hide-details='true' @change="isDeletedChangeEvent($event)"></v-select>
          </v-col>
          <v-col md="3" v-if="blockEnabled">
            <v-select label="Filter Records By" :items="['All', 'Active', 'Blocked']" hide-details='true' @change="isBlockedChangeEvent($event)"></v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
      v-if="editEnabled"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
      v-if="deleteEnabled && !hideDeleteAction"
      class="mr-2"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
      v-if="blockEnabled"
        small
        @click="blockItem(item)"
      >
      {{ item.isBlocked? 'mdi-check': 'mdi-block-helper' }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <div style="padding: 10px;"> 

    No records found <a
    @click="reloadItems"
  >
    reset
  </a>
</div>
    </template>
  </v-data-table>
</template>

<script>
import datatable from '../../../api/datatable';

export default {
  props:{
    resource:{

    },
      headers:{
          required: true
      },
      blockText: String,
      config:{},
  },

  data: () => ({
// Relplace
searchText: '',
items: [],
count: null,

    dialog: false,
    dialogDelete: false,
    dialogBlock: false,
    loading: false,

    // Actions
    editEnabled: true,
    deleteEnabled: true,
    hideDeleteAction: false,
    blockEnabled: true,
    searchEnabled: true,

    //
    currentSelectedItem: {},

    options:{

    },

    editedIndex: -1,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    options: {
    handler() {
      this.fetchItems();
      this.reloadItems();
    },
  },
},

  created () {
    this.initConfig();
  },
  mounted (){


  },

  methods: {
    fetchItems(){
      this.loading = true;
      this.options.search = this.searchText;
      datatable.getList(this.config.resource, this.options).then(data=>{
        this.items = data.data.data.list;
        this.count = data.data.data.count;
        this.loading = false;
      }).catch(error=>{
        this.loading = false;
      })
    },
    reloadItems(){
      this.loading = true;
      this.options.search = this.searchText;
      datatable.reloadList(this.config.resource, this.options).then(data=>{
        this.items = data.data.data.list;
        this.count = data.data.data.count;
        this.loading = false;
      }).catch(error=>{
        this.loading = false;
      })
    },
getColor(status) {
      switch (status) {
        case 'Approved':
          return 'green'; // Color for "Approved" state
        case 'Called':
          return 'blue'; // Color for "Called" state
        case 'Pending':
          return 'orange'; // Color for "Pending" state
        case 'Call Approved':
          return 'green';
          case 'Visit Approved':
          return 'green';
          case 'Construction Approved':
          return 'green';
          case 'Construction Disapproved':
          return 'red';
           case 'Construction Requested':
          return 'orange';
          case 'Visited':
          return 'blue';
        default:
          return 'gray'; // Default color for other states
      }
    },
  handleRowClick(args) {
    if(!this.config.rowClickUrl){
      return
    }
    
    this.$router.push({ path: "/"+this.config.rowClickUrl +'/' + args.id });
  },

    editItem (item) {
      this.$router.push({ path: "/"+this.config.resource +'/' + item.id });
    },

    deleteItem (item) {
      this.dialogDelete = true
    },
    blockItem (item) {
      this.currentSelectedItem = item;
      this.dialogBlock = true
    },

    deleteItemConfirm () {
      datatable.block(this.config.resource, this.currentSelectedItem.id).then(data=>{
        alert('blocked');
      })
      this.closeDelete()
    },
    blockItemConfirm (item) {
      if(this.currentSelectedItem.isBlocked){
        datatable.unblock(this.config.resource, this.currentSelectedItem.id).then(data=>{
        this.fetchItems();
      })
      }else{
        datatable.block(this.config.resource, this.currentSelectedItem.id).then(data=>{
        this.fetchItems();
      })
      }

      this.closeBlock()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeBlock(){
      this.dialogBlock = false;
    },

    handleAction(){
      this.$router.push({path:this.config.actionUrl})
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
    initConfig(){
      this.editEnabled = this.config.enabledActions.includes('edit');
      this.deleteEnabled = this.config.enabledActions.includes('delete');
      this.hideDeleteAction = this.config.enabledActions.includes('hide-delete-action');
      this.blockEnabled = this.config.enabledActions.includes('block');
      if(this.blockEnabled){
        this.headers.push(
          {text: 'Status', 'value': 'isBlocked'}
        )
      }
      if(this.editEnabled || (this.deleteEnabled && !this.hideDeleteAction) || this.blockEnabled  ){
        this.headers.push(
          {
            text: "Actions", 'value': 'actions', sortable: false
          }
        )
      }

      this.searchEnabled = this.config.enabledActions.includes('search');
    },
    searchEvent(event){
          this.options.search = event;
          this.fetchItems();
      },
      isDeletedChangeEvent(event){
        if(event==='Deleted'){
          this.options.isDeleted = 'true';
        }else if(event === 'Not Deleted'){
          this.options.isDeleted = 'false';
        }else{
          this.options.isDeleted = null;
        }
          this.fetchItems();
      },
      isBlockedChangeEvent(event){
        if(event=='Active'){
          this.options.isBlocked = 'false';
        }else if(event == 'Blocked'){
          this.options.isBlocked = 'true';
        }else{
          this.options.isBlocked = null;
        }
        this.fetchItems();
      }
  },
}
</script>

<style></style>
