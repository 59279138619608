import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL;

function getList(resource, options) {
  let extraParams = "";
  if (options.search) {
    extraParams = extraParams + "&search=" + options.search;
  }
  if (options.isBlocked) {
    extraParams = extraParams + "&isBlocked=" + options.isBlocked;
  }
  if (options.isDeleted) {
    extraParams = extraParams + "&isDeleted=" + options.isDeleted;
  }
  if(options.itemsPerPage === -1){
    options.itemsPerPage = null
  }
  return axios.get(
    BaseUrl +
      "/" +
      resource +
      "?page=" +
      options.page +
      "&size=" +
      options.itemsPerPage +
      extraParams
  );
}
function reloadList(resource, options) {
  let extraParams = "";
  if (options.search) {
    extraParams = extraParams + "&search=" + options.search;
  }
  if (options.isBlocked) {
    extraParams = extraParams + "&isBlocked=" + options.isBlocked;
  }
  if (options.isDeleted) {
    extraParams = extraParams + "&isDeleted=" + options.isDeleted;
  }
  return axios.get(
    BaseUrl +
      "/" +
      resource +
      "?page=" +
      options.page +
      "&size=" +
      options.itemsPerPage +
      extraParams
  );
}

function block(resource, id) {
  return axios.put(BaseUrl + "/" + resource + "/block/" + id);
}

function unblock(resource, id) {
  return axios.put(BaseUrl + "/" + resource + "/unblock/" + id);
}

export default {
  getList,
  reloadList,
  block,
  unblock,
};
